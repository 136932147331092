.pisoseccion-parent > div { 
    position: relative; 
 }
 .pisoseccion-parent { 
     max-width: 100%;
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr;
     grid-column-gap: 2rem;
     -moz-column-gap: 2rem;
     grid-column-gap: 2rem;
     column-gap: 2rem;
     grid-row-gap: 2rem;
     grid-row-gap: 2rem;
     row-gap: 0rem;
 }
 
 .primer-bloque {
     display: grid;
     grid-gap: 2rem;
     gap: 2rem;
     grid-area: 1 / 1 / auto / 3;
     margin-top: 3rem;
        
 }
 .segundo-bloque {
     grid-area: 1 / 3 / auto / 4;
     grid-gap: 2rem;
     gap: 2rem;
     display: grid;
     margin-top: 5rem;
 }
 .tercer-bloque {
     grid-area: 1 / 4 / auto / 5;
     grid-gap: 2rem;
     gap: 2rem;
     display: grid;
     margin-top: 0.2rem;
 }
 .separador-news{
     border-bottom: 1px solid var(--dark-black);
 }
 
 .grid-item {
     position: relative;
     padding-bottom: 0.8rem;
     /* border-bottom: 1px solid var(--light-gray); */
     border: 1px solid var(--light-gray);
	 border-radius: 7px;
     height: min-content;
     margin-bottom: 1.3rem;
 }
 .grid-item2 {
     position: relative;
     padding-bottom: 0.8rem;
     border: 1px solid var(--light-gray);
	 border-radius: 7px;
     height: min-content;
 }
 
 .grid-item3 {
    position: relative;
    padding-bottom: 0.8rem;
    
}
 .contenedor-gral-tittulo-container { 
    display: block;
     grid-gap: 2rem;
     gap: 2rem;
     grid-area: 1 / 1 / auto / 4;}
 .contenedor-gral-tittulo-container1 { 
    border-top:.09375rem solid var(--dark-black); margin:20px 0 0 0; display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-area: 4 / 1 / auto / 4;}    
 .contenedor-gral-tittulo-container h2{ margin:0.5rem 0;}
 .contenedor-gral-tittulo-container h2 a {color:var(--dark-black); text-transform: uppercase; padding: 0; margin:0 10px 0 0; text-decoration: none;}
 .contenedor-gral-tittulo-container .contenedor-left-titulo {background-color:#ffffff; padding:0; float: left;}
 
 .scroll-margin { margin: 7px 0 10px 0;}
 
 .menu-el-scroll { padding: 4px 0px 12px 10px; white-space: nowrap; overflow-x: hidden; scroll-behavior: inherit; display: block; overflow-y: hidden;}
 .menu-el-scroll ul { margin: 0; padding: 0!important;}
 .menu-el-scroll li { display: inline; margin: 6px 8px 0 2px; padding: 7px; font-weight: 400; font-size: 0.8rem; background-color: #eee; color: #555555; padding: 7px 14px 7px 14px; border-radius: 14px;}    
 .menu-el-scroll li a { color: #555555!important; text-decoration: none;}  
 
 .menu-el-scroll li .com-link { white-space: nowrap; position: relative;}
 .menu-el-scroll .com-link:after { content: "•"; position: absolute; color: #ccc; right: -1rem;}
 
 @media (max-width: 768px) {
     .pisoseccion-parent {
         display: block;
     }
     .primer-bloque {
         padding-bottom: 2.2rem;
         grid-template-columns: 1fr;
         grid-area: auto;
         margin-top:0;
     }
     .segundo-bloque {
         padding-bottom: 0.5rem;
         grid-template-columns: 1fr;
         grid-area: auto;
         margin-top:0;
     }
     .tercer-bloque{
        margin-top: 1rem;
     }

     .container-banner-chico{
         position: 4;
     }
     .contenedor-gral-tittulo-container {margin:0 0 1rem 0; height:auto;}
     .contenedor-gral-tittulo-container .contenedor-left-titulo h2 {margin:0;}
     .contenedor-gral-tittulo-container1 { margin: 0 0 20px 0;}
 
     .menu-el-scroll {clear: both; padding:0.9rem 0rem 0.9rem 0rem; white-space: nowrap; overflow-x: hidden; scroll-behavior: inherit; display: block; overflow: scroll; overflow-y: hidden;}
     .scroll-margin {margin: 4px 0;}

     .grid-item {
        margin-bottom: 1.0rem;
     }
     
     .grid-item2 {
        margin-bottom: 1rem;
     }
 }
 
 